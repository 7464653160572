.login {
    background-color: black;
    height: 90vh;
    padding-top: 5vh;
    color: white;
    font-family: "Montserrat", cursive;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 4;
}

.login .header {
    flex: 1;
    width: 50vw;
}

.login .columns {
    display: flex; 
    flex: 3;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100vw;
}


.login .columns .column {
    display: flex;
    height: 100%;
    width: 20%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.login .columns .transferbutton {
    height: 10%;
    margin-left: 5vw; 
    margin-right: 5vw;
    background-color: white;
    color: black;
}

.login .columns .column.apple {
    background: rgb(252,92,64);
    background: linear-gradient(0deg,rgba(245,50,131,1)  30%,  rgba(252,92,64,1) 78%);
}

.login .columns .column.spotify {
    background: rgb(30,215,96);
background: linear-gradient(0deg, rgb(24, 153, 93) 30%, rgba(30,215,96,1)  78%);
}

.login .columns .column img {
    width: 50%;
    height: auto;
}

.login .columns .column .btn {
    color: white;
    background-color: black;
    box-shadow: none;
    outline: none;
    border-color: black;
    width: 50%;
    font-size: 20px;
}

.login .columns .column .btn:hover {
    background-color: white;
    color: black;
}
.home {
    background-color: black;
    height: 90vh;
    padding-top: 10vh;
    color: white;
    font-family: "Montserrat", cursive;
    display: flex; 
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    flex: 4;
}

.home .header {
    flex: 1;
    width: 100vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.home .columns {
    display: flex; 
    flex: 3;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
}


.home .columns .column {
    display: flex;
    height: 80vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: scroll;
    flex: 1;
    padding-right: 1%;
    margin-left: 8vw;
    margin-right: 8vw;
}


/* .home .columns .column.apple {
    background: rgb(252,92,64);
    background: linear-gradient(0deg,rgba(245,50,131,1)  30%,  rgba(252,92,64,1) 78%);
}

.home .columns .column.spotify {
    background: rgb(30,215,96);
background: linear-gradient(0deg, rgb(24, 153, 93) 30%, rgba(30,215,96,1)  78%);
} */


.home .columns .playlist {
    width: 100%;
    display: flex; 
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
    min-height: 10vh;
    max-height: 10vh;
    overflow-y: hidden;
    margin-top: 2vh;
    justify-content: space-evenly;
    align-items: center;
}

.home .columns  .playlist img {
    max-height: 100%;
    min-height: 100%;
    width: auto;
}

.home .columns .playlist h3 {
    flex: 3; 
    font-size: 22px;
    padding-left: 1%;
    padding-right: 1%;
}

.home .columns .playlist .btn {
    flex: 1;
    height: 60%;
    outline: none;
    font-weight: 600;
    font-size: 22px;
    border: black; 
    box-shadow: none;
}



.home .applelogo {
    color: rgb(252,92,64);
    color: linear-gradient(0deg,rgba(245,50,131,1)  30%,  rgba(252,92,64,1) 78%);
}


.home .spotifylogo {
    color: rgb(30,215,96);
    color: linear-gradient(0deg, rgb(24, 153, 93) 30%, rgba(30,215,96,1)  78%);
}

.home .columns .apple .playlist .btn {

    background: linear-gradient(0deg,rgba(245,50,131,1)  30%,  rgba(252,92,64,1) 78%);
}
.home .columns .spotify .playlist .btn {
    background: rgb(30,215,96);
    background: linear-gradient(0deg, rgb(24, 153, 93) 30%, rgba(30,215,96,1)  78%);
}

.home .columns .playlist .btn:hover {
    background: white;
    color: black;
}

.home .columns .column::-webkit-scrollbar { 
    width: 10px;
    background-color: rgba(0, 0, 0, 0.347);
    border-radius: 10px;
}
.home .columns .column::-webkit-scrollbar-thumb { 
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.789);
}
